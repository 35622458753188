import http from '@/utils/http'

// 企业查询
export function get_company_list(data) {
  return http({
    url: '/cs/ac-get_company_list',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}

//按公司找竞品
export function get_ad_compete_list(data) {
  return http({
    url: '/cs/ac-get_ad_compete_list',
    method: 'get',
    params: data
  })
}

//生成对比页面id
export function init_compete_id(data) {
  return http({
    url: '/cs/ac-init_compete_id',
    method: 'get',
    params: data
  })
}

//根据对比ID产生数据
export function get_pk_info(data) {
  return http({
    url: '/cs/ac-get_pk_info',
    method: 'get',
    params: data
  })
}


//根据对比ID产生数据
export function get_pk_info_by_sms_id(data) {
  return http({
    url: '/cs/ac-get_pk_info_by_sms_id',
    method: 'get',
    params: data
  })
}

// 赠送vip开户
export function open_account(data) {
  return http({
    url: '/cc/ac-open_account',
    transformRequest: [(data) => {
      return data
    }],
    header: {
      'Content-Type': 'application/json'
    },
    method: 'post',
    data: JSON.stringify(data)
  })
}
